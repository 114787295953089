import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentWrapper from "../styles/ContentWrapper"
import { splashScreen } from "../config"
import { MDXRenderer } from "gatsby-plugin-mdx"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 100px 25px 0 25px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  h2 {
    color: #000000;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 50px;
  margin: 200px 0 30px 0;
  h6,
  strong {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: #000000;
  }
  p {
    margin: 0;
  }
  p:nth-child(2) {
    margin-bottom: 16px;
  }
  ol {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  li a {
    display: block;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    :hover {
      text-decoration: underline;
    }
  }
  .description {
    grid-column-end: span 2;
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
  .map {
    grid-column: 1 / span 6;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`

const TeachingPage = ({ data }) => {
  const { frontmatter, body, exports } = data.teaching.edges[0].node
  const { teaching } = exports
  return (
    <Layout splashScreen={splashScreen}>
      <SEO title={frontmatter.title} />
      <StyledSection>
        <StyledContentWrapper>
          <Grid>
            <div>
              <h6>Date:</h6>
              <p>{frontmatter.date}</p>
              <h6>Keywords:</h6>
              <p>{frontmatter.tags.join(", ")}</p>
            </div>
            <div>
              <h6>{teaching.title}</h6>
              <ol>
                {teaching.items.map(({ title, link }, key) => (
                  <li key={key}>
                    <a href={link}>{title}</a>
                  </li>
                ))}
              </ol>
            </div>
            <MDXRenderer>{body}</MDXRenderer>
            <Img
              fluid={frontmatter.map.childImageSharp.fluid}
              className="map"
            />
          </Grid>
        </StyledContentWrapper>
      </StyledSection>
    </Layout>
  )
}

TeachingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TeachingPage

export const pageQuery = graphql`
  {
    teaching: allMdx(filter: { fileAbsolutePath: { regex: "//teaching//" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            date
            tags
            map {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          exports {
            teaching {
              title
              items {
                title
                link
              }
            }
          }
        }
      }
    }
  }
`
